import { useEffect, useLayoutEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useStaticQuery, graphql } from 'gatsby';
import { css } from '@emotion/react';
import { BP } from '../styles/mixins';
import { setProperties } from '../lib/utils';

/**
 * Get CSS globals from Prismic
 */
export function usePrismicVariables() {
  const { prismicSettings } = useStaticQuery<GatsbyTypes.DesignQuery>(graphql`
      query Design {
        prismicSettings {
          data {
            padding_xs
            padding_s
            padding_m
            padding_l
            padding_xl
            margin_xs
            margin_s
            margin_m
            margin_l
            margin_xl
            gutter_xs
            gutter_s
            gutter_m
            gutter_l
            gutter_xl
            radius_small
            radius_large
            radius_button
            color_primary
            color_secondary
            color_tertiary
            color_accent
            color_background
            color_primary_dark
            color_secondary_dark
            color_tertiary_dark
            color_accent_dark
            color_background_dark
          }
        }
      }
    `),
    { data } = prismicSettings!;

  return data
    ? css`
        :root {
          /* Colors — Light Mode */
          --color-primary-light: ${data.color_primary};
          --color-secondary-light: ${data.color_secondary};
          --color-tertiary-light: ${data.color_tertiary};
          --color-accent-light: ${data.color_accent};
          --color-background-light: ${data.color_background};

          /* Colors — Dark Mode */
          --color-primary-dark: ${data.color_primary_dark};
          --color-secondary-dark: ${data.color_secondary_dark};
          --color-tertiary-dark: ${data.color_tertiary_dark};
          --color-accent-dark: ${data.color_accent_dark};
          --color-background-dark: ${data.color_background_dark};

          /* Layout */
          --padding: ${data.padding_xl}px;
          --margin: ${data.margin_xl}px;
          --gutter: ${data.gutter_xl}px;
          @media (${BP.l}) {
            --padding: ${data.padding_l}px;
            --margin: ${data.margin_l}px;
            --gutter: ${data.gutter_l}px;
          }
          @media (${BP.m}) {
            --padding: ${data.padding_m}px;
            --margin: ${data.margin_m}px;
            --gutter: ${data.gutter_m}px;
          }
          @media (${BP.s}) {
            --padding: ${data.padding_s}px;
            --margin: ${data.margin_s}px;
            --gutter: ${data.gutter_s}px;
          }
          @media (${BP.xs}) {
            --padding: ${data.padding_xs}px;
            --margin: ${data.margin_xs}px;
            --gutter: ${data.gutter_xs}px;
          }

          /* UI */
          --radius-small: ${data.radius_small}px;
          --radius-large: ${data.radius_large}px;
          --radius-button: ${data.radius_button}px;
        }
      `
    : '';
}

/**
 * Inverted colour mode
 * @param invert Whether to invert colours
 */
export function useInverted(invert: boolean) {
  useLayoutEffect(() => {
    invert &&
      setProperties({
        '--color-primary': 'var(--color-primary-dark)',
        '--color-secondary': 'var(--color-secondary-dark)',
        '--color-tertiary': 'var(--color-tertiary-dark)',
        '--color-accent': 'var(--color-accent-dark)',
        '--color-background': 'var(--color-background-dark)'
      });

    return () => {
      setProperties({
        '--color-primary': 'var(--color-primary-light)',
        '--color-secondary': 'var(--color-secondary-light)',
        '--color-tertiary': 'var(--color-tertiary-light)',
        '--color-accent': 'var(--color-accent-light)',
        '--color-background': 'var(--color-background-light)'
      });
    };
  }, []);
}

/**
 * Patched useMediaQuery for SSR
 * Until https://github.com/contra/react-responsive/issues/162 resolved
 * @param {string} query
 * @param {boolean} fallback
 * @param {function} callback
 */
export function useBreakpoint(
  query: string,
  fallback = true,
  callback?: () => any
) {
  const [browserFlushed, setBrowserFlushed] = useState(false);
  const matched = useMediaQuery({ query }, undefined, callback);
  useLayoutEffect(() => setBrowserFlushed(true), []);

  if (typeof window !== 'undefined' && browserFlushed) {
    return matched;
  }
  return fallback;
}

/**
 * Set styles on a page and remove on dismount
 */
export function usePageStyles(styles: object) {
  useEffect(() => {
    Object.keys(styles).forEach((style) => {
      document.body.style[style as any] = styles[style as keyof typeof styles];
    });

    return () =>
      Object.keys(styles).forEach((style) => {
        document.body.style.removeProperty(style);
      });
  }, []);
}
