import { css } from '@emotion/react';
import { normalize } from 'satchel-css';
import { TYPESET, BP } from './mixins';

/**
 * Base document styles
 */
export const base = css`
  ${normalize({
    base: 'remedy',
    fontSmoothing: true,
    resetMargins: true,
    resetHeadings: true
  })}

  html {
    background-color: var(--color-background);
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }

  body {
    ${TYPESET.scale0}
    color: var(--color-primary);
    font-family: var(--font-family);
    position: relative;
    min-height: 100vh;
  }

  strong {
    font-weight: var(--font-weight-bold);
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  /**
   * Global formatting labels
   * Should possibly be restrained to only children of RichText
   */
  .color-primary {
    color: var(--color-primary);
  }
  .color-secondary {
    color: var(--color-secondary);
  }
  .color-tertiary {
    color: var(--color-tertiary);
  }
  .color-accent {
    color: var(--color-accent);
  }
`;
