import isDarkColor from 'is-dark-color';

/**
 * Resole relative URLs against a source
 * @param link URL to resolve
 * @param source Source URL to resolve against
 */
export function resolveUrl(link: string, source: string) {
  if (!link) {
    return source;
  }

  const url = new URL(source, link);

  return !/^https?:\/\//i.test(link) ? url : link;
}

/**
 * Helper to update CSS variables
 * @param properties Object of CSS properties
 */
export function setProperties(properties: any) {
  Object.keys(properties).forEach((property: string) =>
    document.documentElement.style.setProperty(property, properties[property])
  );
}

/**
 * Strip outer HTML tags from HTML string
 */
export function innerHTML(html: string) {
  return html.replace(/^<[^>]+>|<\/[^>]+>$|<[^/>][^>]*><\/[^>]+>/g, '');
}

export function darkBackgrounds(background: string[]) {
  return (
    background &&
    ((background[0] && isDarkColor(background[0])) ||
      (background[1] && isDarkColor(background[1])))
  );
}
