import { css } from '@emotion/react';
import { BP } from './mixins';

/**
 * Design system
 * Using Pollen as a foundation (https://pollen.style)
 * Additional variables set dynamically from Prismic in ../index.tsx
 */
export const variables = css`
  :root {
    /* Typography */
    --font-family: 'Inter', sans-serif;
    --font-weight-bold: 600;
    --font-weight-medium: 500;

    /* Typography — Scale overrides */
    --scale-4: 3rem;
    @media (min-width: ${BP.m}) {
      --scale-4: 2.6875rem;
    }
    @media (min-width: ${BP.s}) {
      --scale-4: 2.25rem;
    }
    @media (${BP.xs}) {
      --scale-4: 2rem;
    }

    /* Colours */
    --color-primary: var(--color-primary-light);
    --color-secondary: var(--color-secondary-light);
    --color-tertiary: var(--color-tertiary-light);
    --color-accent: var(--color-accent-light);
    --color-background: var(--color-background-light);

    --color-black: #000;
    --color-grey-900: #303030;
    --color-grey-700: #ebebeb;
    --color-grey-600: #8c8c8c;
    --color-grey-500: #d7d7d7;
    --color-grey-300: #fafafa;
    --color-grey-100: #f7f7f7;

    /*Layout — Constants */
    --section-gutter: var(--spacing-5);
    --section-gutter-double: calc(var(--section-gutter) * 2);
    --mobile-text-gutter: 12px;

    --page-max-width: calc(105rem - var(--margin) * 2);
    --page-grid: minmax(var(--margin), 1fr) minmax(0, var(--page-max-width))
      minmax(var(--margin), 1fr);
    --content-grid: repeat(12, 1fr);
  }
`;
