import { css } from '@emotion/react';
import { subgrid } from 'satchel-css';

/**
 * Global breakpoints
 */
export const BP = {
  xs: 'max-width: 31.5em',
  s: 'max-width: 46em',
  m: 'max-width: 66.75em',
  l: 'max-width: 105em',
  xl: 'min-width: 105em'
};

/**
 * Typography stacks
 */
export const TYPESET = {
  scale5: css`
    font-size: 5rem;
    line-height: 1.2125;
    letter-spacing: -0.0225em;
    @media (${BP.m}) {
      font-size: 3.75rem;
      line-height: 1.2;
    }
    @media (${BP.s}) {
      font-size: 3rem;
      line-height: 1.29;
    }
    @media (${BP.xs}) {
      font-size: 2.6875rem;
      line-height: 1.209;
    }
  `,
  scale4: css`
    font-size: 3rem;
    line-height: 1.27;
    letter-spacing: -0.0225em;
    @media (${BP.l}) {
      font-size: 3rem;
    }
    @media (${BP.m}) {
      font-size: 2.6875rem;
      line-height: 1.255;
      letter-spacing: -0.0225em;
    }
    @media (${BP.s}) {
      font-size: 2.25rem;
      line-height: 1.25;
      letter-spacing: -0.0222em;
    }
    @media (${BP.xs}) {
      font-size: 2rem;
      line-height: 1.218;
      letter-spacing: -0.0225em;
    }
  `,
  scale3: css`
    font-size: 1.5rem;
    line-height: 1.4;
    letter-spacing: -0.0195em;
  `,
  scale2: css`
    font-size: 1.25rem;
    line-height: 1.4;
    letter-spacing: -0.01667em;
  `,
  scale1: css`
    font-size: 1.125rem;
    line-height: 1.5;
    letter-spacing: -0.0139em;
  `,
  scale0: css`
    font-size: 1rem;
    line-height: 1.375;
    letter-spacing: -0.01125em;
  `,
  scale00: css`
    font-size: 0.875rem;
    line-height: 1.43;
    letter-spacing: -0.0062em;
  `
};

/**
 * Subgrids with container inheritence
 */
export const contentGrid = css`
  display: grid;
  grid-template-columns: var(--content-grid);
  grid-column-gap: var(--gutter);
`;

export const subPageGrid = css`
  ${subgrid}
  & > * {
    grid-column: 2 / 3;
  }
`;

/** Inline richtext reset */
export const inlineRichtext = css`
  & > p:first-of-type {
    margin-top: 0;
  }
  & > p:last-of-type {
    margin-bottom: 0;
  }
`;

/** Colour helpers  */
export const primaryColor = css`
  color: var(--color-primary);
  & > * {
    color: var(--color-primary);
  }
`;

/** Alignment helper */
export type Alignment = 'Left' | 'Centered' | 'Centered Desktop';
export function setAlignment(align: Alignment) {
  return [
    align === 'Left' &&
      css`
        text-align: left;
      `,
    align === 'Centered' &&
      css`
        text-align: center;
      `,
    align === 'Centered Desktop' &&
      css`
        text-align: center;
        @media (${BP.s}) {
          text-align: left;
        }
      `
  ];
}
