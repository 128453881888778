import { css, Global } from '@emotion/react';
import { graphql, PageProps, useStaticQuery } from 'gatsby';
import { LogoJsonLd } from 'gatsby-plugin-next-seo';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import smoothscroll from 'smoothscroll-polyfill';
import { usePrismicVariables } from '../lib/hooks';
import globalStyles from '../styles';

const APPLE_ICON_SIZES = [48, 72, 96, 144, 192, 256, 384, 512];

/**
 * App container
 * Entrypoint for the application
 */
export default function App({ children }: PageProps) {
  const {
      prismicSettings
    } = useStaticQuery<GatsbyTypes.GlobalSettingsQuery>(graphql`
      query GlobalSettings {
        prismicSettings {
          data {
            favicon {
              url
            }
          }
        }
      }
    `),
    { data } = prismicSettings!;

  useEffect(() => {
    smoothscroll.polyfill();
  });

  return (
    <>
      <Global styles={[...globalStyles, usePrismicVariables()]} />

      {/* Asset meta */}
      <Helmet>
        <link rel="manifest" href="manifest.webmanifest" />
        <link rel="shortcut icon" href={data?.favicon?.url} />
        {APPLE_ICON_SIZES.map((size) => (
          <link
            rel="apple-touch-icon"
            sizes={`${size}x${size}`}
            href={`${data?.favicon?.url}&w=${size}`}
            key={size}
          />
        ))}
      </Helmet>
      <LogoJsonLd
        logo={data?.favicon?.url || ''}
        url="https://karimjoreige.com"
      />

      {/* The page */}
      <main
        css={css`
          position: relative;
          display: grid;
          grid-template-columns: var(--page-grid);
          align-items: flex-start;
          & > * {
            grid-column: 2 / 3;
          }
        `}
      >
        {children}
      </main>
    </>
  );
}
